<template>
  <div>
    <div class="menu-header">
      <a-icon class="arrow" type="arrow-left" @click="$router.go(-1)" />
      <div class="title">参与者列表</div>
      <div class="menu" v-if="detailDescription.status!=6" @click="showRefused" >
        <img class="icon" src="../../assets/icons/icon-refuse.png" />
        <div class="text">拒绝签署</div>
      </div>
    </div>
    <div class="modalTitle">签署详情</div>
    <a-descriptions bordered title="" >
      <a-descriptions-item :span="3" label="参与者ID">
        <div style="display:flex;align-items:center">
          <span>{{ detailDescription.ptsNo }}</span>
          <span style="margin-left:15px">{{ detailDescription.icfName }}</span>
          <span style="margin-left:15px">{{ detailDescription.createTime | moment('YYYY-MM-DD') }}</span>
          <span style="margin-left:15px" class="pro-status pro-small" :class="detailDescription.status | enumValueByKey($const.SIGNATURE_STATUS,'style')" >{{ detailDescription.status | enumValueByKey($const.SIGNATURE_STATUS) }}</span>
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="项目ID">{{ detailDescription.proNo }}</a-descriptions-item>
      <a-descriptions-item label="site编号">{{ detailDescription.orgNo }}</a-descriptions-item>
      <a-descriptions-item label="创建者">{{ detailDescription.createName }}</a-descriptions-item>
      <a-descriptions-item label="知情版本">
        {{ detailDescription.icfName }}
      </a-descriptions-item>
      <a-descriptions-item label="签署方式">{{ detailDescription.signType == 2 ? '现场签署' : '远程签署' }}</a-descriptions-item>
      <a-descriptions-item label="当前状态">
        {{ detailDescription.status | enumValueByKey($const.SIGNATURE_STATUS) }}
      </a-descriptions-item>
      <a-descriptions-item label="研究者">{{ detailDescription.researcherName }}</a-descriptions-item>
      <!-- <a-descriptions-item label="知情签署" v-if="this.fileListSee && detailDescription.signType == 2">
        <a-button type="primary" @click="fileSee">查看文件</a-button>
      </a-descriptions-item> -->
    </a-descriptions>
    <a-tabs >
      <a-tab-pane v-if="icfConfig != 3 && recordVisible" key="1" tab="知情过程记录">
        <div class="recordView">
          <div class="modalTitle">知情过程记录</div>
          <a-row :gutter="18">
            <a-col :md="6" :sm="24">查看次数：</a-col>
            <a-col :md="12" :sm="24">{{ consentProgress.view }}次</a-col>
          </a-row>
          <a-row class="row" :gutter="18">
            <a-col :md="6" :sm="24">最近一次时间：</a-col>
            <a-col :md="6" :sm="24">{{ consentProgress.recentTime | formatVideo(1) }}</a-col>
            <a-col :md="6" :sm="24">预计时间：</a-col>
            <a-col v-if="detailDescription.estimatedTime" :md="6" :sm="24">{{ detailDescription.estimatedTime | formatVideo() }}</a-col>
          </a-row>
          <a-row v-if="consentProgress.startTime" class="row" :gutter="18">
            <a-col :md="6" :sm="24">{{ consentProgress.startTime | moment() }}</a-col>
            <a-col :md="12" :sm="24">点击开始</a-col>
          </a-row>
          <a-row class="row" :gutter="18">
            <a-col :md="24" :sm="24">
              <a-row :gutter="18" class="recordTitle">
                <a-col :md="12" :sm="24"><div style="word-wrap:break-word;">知情文件</div></a-col>
                <a-col :md="12" :sm="24"><span v-if="propagandaList.costTime">{{ propagandaList.costTime | formatVideo(1) }}</span></a-col>
              </a-row>
              <a-row v-for="item in propagandaList.detailList" :key="item.id" class="row" :gutter="18">
                <a-col :md="6" :sm="24">{{ item.createTime | moment() }}</a-col>
                <a-col :md="12" :sm="24">{{ item.type | enumValueByKey($const.OPERATION_BEHAVIOR) }}</a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="18" v-for="(listItem) in detailLog" :key="listItem.id">
            <a-col :md="24" :sm="24">
              <a-row :gutter="18" class="recordTitle">
                <a-col :md="12" :sm="24"><div style="word-wrap:break-word;">{{ listItem.title }}</div></a-col>
                <a-col :md="12" :sm="24"><span v-if="listItem.costTime">{{ listItem.costTime | formatVideo(1) }}</span></a-col>
              </a-row>
              <a-row v-for="item in listItem.detailList" :key="item.id" class="row" :gutter="18">
                <a-col :md="6" :sm="24">{{ item.createTime | moment() }}</a-col>
                <a-col :md="12" :sm="24">{{ item.type | enumValueByKey($const.OPERATION_BEHAVIOR) }}</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>
      <a-tab-pane v-if="icfConfig == 1 && videoVisible" key="2" tab="视频沟通">
        <div class="recordView" >
          <div class="modalTitle">视频沟通</div>
          <a-row class="row" :gutter="18">
            <a-col :md="6" :sm="24">申请时间：</a-col>
            <a-col :md="12" :sm="24">{{ detailDescription.videoApplyTime | moment() }}</a-col>
          </a-row>
          <div v-for="(item,index) in detailVideoList" :key="index" style="margin-bottom:20px">
            <a-row v-if="item.videoReserveStartTime" class="row" :gutter="18">
              <a-col :md="6" :sm="24">预约时间：</a-col>
              <a-col :md="12" :sm="24">{{ item.videoReserveStartTime | moment() }}</a-col>
            </a-row>
            <a-row v-if="item.videoReserveEndTime" class="row" :gutter="18">
              <a-col :md="6" :sm="24"></a-col>
              <a-col :md="12" :sm="24">{{ item.videoReserveEndTime | moment() }}</a-col>
            </a-row>
            <a-row v-if="item.videoStartTime" :gutter="18" class="row">
              <a-col :md="6" :sm="24">会议开始：</a-col>
              <a-col :md="12" :sm="24">{{ item.videoStartTime | moment() }}</a-col>
            </a-row>
            <a-row v-if="item.videoEndTime" class="row" :gutter="18">
              <a-col :md="6" :sm="24">会议结束：</a-col>
              <a-col :md="12" :sm="24">{{ item.videoEndTime | moment() }}</a-col>
            </a-row>

            <div v-for="(item1,index1) in getVideoList(item)" :key="index1" class="row">
              <video
                :src="item1"
                style="width:300px;height:160px"
                controls="controls"
                controlsList="nodownload"
              />
            </div>
          </div>
        </div>
      </a-tab-pane>
      <!-- <a-tab-pane v-if="signVisible || detailDescription.userFinshTime" key="3" tab="知情签署">
        <div class="recordView" >
          <div class="modalTitle">知情签署</div>
          <div v-for="(item,index) in signList" :key="index" style="margin-top: 20px">
            <div class="subTitle">
              <div>{{ item.fileTitle }}</div>
              <div class="subTitle-status">
                <a-tag :color="getSignStatusColor(item.status)"> {{ item.status | filterSignStatus }} </a-tag>
              </div>
            </div>
            <div class="modalRow" v-if=" item.ptsFinishTime" >
              <span>{{ item.ptsFinishTime | moment() }} 参与者 {{ item.ptsNo }}</span>
              <div>完成签署</div>
            </div>
            <div class="modalRow" v-if="item.researcherFinishTime" >
              <span>{{ item.researcherFinishTime | moment() }} 研究者 {{ item.piNo }}</span>
              <div>完成签署</div>
            </div>
            <div class="modalRow" v-if="item.status == 2" >
              <span>拒绝签署：</span>
              <div>{{ item.revokeTime | moment() }}</div>
            </div>
            <div class="modalRow" v-if="item.status == 2" >
              <span>拒绝理由：</span>
              <div>{{ item.reason }}</div>
            </div>
            <div class="row" v-if="item.status == 1 && detailDescription.signFileUrl">
              <a @click="showPreview" > 查看文件</a>
            </div>
          </div>
        </div>
      </a-tab-pane> -->
      <a-tab-pane v-if="detailDescription.signStatus == 2" key="3" tab="电子签署">
        <div>
          <div class="sign-content">
            <div class="sign-content-left">
              <div>参与者</div>
              <div>
                <div class="sign-status" v-if="detailDescription.status!=6 && !detailDescription.userFinshTime">待签署</div>
                <div class="sign-status" v-if="detailDescription.userFinshTime">已签署</div>
                <div class="sign-status status-color" v-if="detailDescription.status==6 && !detailDescription.userFinshTime">已拒绝</div>
              </div>
            </div>
            <div class="sign-time" v-if="detailDescription.userFinshTime">{{ detailDescription.userFinshTime | moment() }}</div>
          </div>
          <div class="sign-code" v-if="detailDescription.status!=6 && !detailDescription.userFinshTime">
            <vue-qr
              ref="qrCodeUrl"
              :text="signUrl"
              :size="200">
            </vue-qr>
            <div class="code-text">参与者</div>
          </div>
          <div class="sign-content" v-if="detailDescription.witnessId">
            <div class="sign-content-left">
              <div>见证人</div>
              <div v-if="detailDescription.userFinshTime">
                <div class="sign-status" v-if="detailDescription.status!=6 && !detailDescription.witnessFinishTime">待签署</div>
                <div class="sign-status" v-if="detailDescription.witnessFinishTime">已签署</div>
                <div class="sign-status status-color" v-if="detailDescription.status==6 && !detailDescription.witnessFinishTime">已拒绝</div>
              </div>
            </div>
            <div class="sign-time" v-if="detailDescription.witnessFinishTime">{{ detailDescription.witnessFinishTime | moment() }}</div>
          </div>
          <div class="sign-code" v-if="detailDescription.witnessId && detailDescription.userFinshTime && detailDescription.status!=6 && !detailDescription.witnessFinishTime">
            <vue-qr
              ref="qrCodeUrl"
              :text="signUrl"
              :size="200">
            </vue-qr>
            <div class="code-text">见证人</div>
          </div>
          <div class="sign-content">
            <div class="sign-content-left">
              <div>研究者</div>
              <div v-if="(detailDescription.witnessId && detailDescription.witnessFinishTime) || (!detailDescription.witnessId && detailDescription.userFinshTime)">
                <div class="sign-status" v-if="detailDescription.status!=6 && !detailDescription.researcherFinshTime">待签署</div>
                <div class="sign-status" v-if="detailDescription.researcherFinshTime">已签署</div>
                <div class="sign-status status-color" v-if="detailDescription.status==6 && !detailDescription.researcherFinshTime">已拒绝</div>
              </div>
            </div>
            <div class="sign-time" v-if="detailDescription.researcherFinshTime">{{ detailDescription.researcherFinshTime | moment() }}</div>
          </div>
          <div class="sign-code" v-if="((!detailDescription.witnessId && detailDescription.userFinshTime) || (detailDescription.witnessId && detailDescription.witnessFinishTime)) && detailDescription.status!=6 && !detailDescription.researcherFinshTime">
            <vue-qr
              ref="qrCodeUrl"
              :text="signUrl"
              :size="200">
            </vue-qr>
            <div class="code-text">研究者</div>
          </div>
          <div class="file-upload" v-if="clientICFApplyDTO.status === 5">
            <a-icon class="file-icon" type="file-pdf" theme="filled" />
            <a-button type="primary" @click="fileSee(detailDescription.signFileUrl)">查看文件</a-button>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane v-if="detailDescription.signStatus == 1" key="4" tab="纸质签署">
        <div class="file-upload" v-if="clientICFApplyDTO.status !== 5 && clientICFApplyDTO.status !== 6">
          <div>签署文件上传</div>
          <a-button type="primary" @click="uploadTap">文件上传</a-button>
        </div>
        <div class="file-upload" v-if="this.fileListSee">
          <a-icon class="file-icon" type="file-pdf" theme="filled" />
          <a-button type="primary" @click="fileSee()">查看文件</a-button>
        </div>
        <div class="file-upload" v-if="clientICFApplyDTO.status === 6">拒绝签署理由： {{ clientICFApplyDTO.reason }}</div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      width="80%"
      title="预览"
      :visible="pdfVisible"
      :footer="null"
      @cancel="pdfVisible = false"
    >
      <!-- <pdf
        ref="pdf"
        v-for="i in numPages"
        :key="i"
        :src="fileUrl"
        :page="i"
      ></pdf> -->
      <div id="pdf" :style="{'height':modalHeight + 'px'}" >
        <div id="demo"></div>
      </div>
    </a-modal>
    <a-modal
      title="纸质签署"
      :visible="paVisible"
      @ok="handleSubmit"
      @cancel="paCancel"
    >
      <div>
        <van-uploader
          accept=".pdf, .png, .jpg, .jpeg"
          v-model="fileList"
          multiple
          :after-read="afterRead"
          @click-preview="handlePer"/>
      </div>
    </a-modal>
    <a-modal
      width="50%"
      title="拒绝签署"
      :visible="refusedVisible"
      @cancel="refusedVisible = false"
      @ok="toRefused"
    >
      <a-textarea v-model="refusedRemark" placeholder="请输入拒绝签署的理由" :maxLength="400" :rows="4" >
      </a-textarea>
    </a-modal>
    <a-modal
      width="90%"
      title="文件预览"
      :visible="viewVisible"
      :footer="null"
      @cancel="viewCancel"
    >
      <div id="pdfview" style="height:70vh;"></div>
    </a-modal>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import { getConsentDetail, getApplyPaper, getSignUrl, applyPaper, upload, getApplyRefuse } from '@/api/consent/consent'
// import pdf from 'vue-pdf-sign'
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
export default {
  components: { vueQr },
  data () {
    return {
      consentProgress: {}, // 知情过程（后端数据结构原因取四个值）
      detailDescription: {}, // 最上面的详情描述列表
      clientICFApplyDTO: {},
      detailLog: [], // 具体的知情过程记录
      recordVisible: false,
      videoVisible: false,
      signVisible: false,
      appointVisible: false,
      modalVisible: false, // 模态框
      detail: null,
      videoList: [],
      detailVideoList: [],
      propagandaList: {},
      numPages: null,
      pdfVisible: false,
      fileUrl: '',
      pdfh5: null,
      newPdfUrl: '',
      modalHeight: 500,
      icfConfig: '',
      signList: [],
      fileListSee: false,
      signUrl: '',
      paVisible: false,
      fileList: [],
      refusedVisible: false,
      refusedRemark: '',
      viewVisible: false
    }
  },
  filters: {
    filterUserType (val) {
      if (val === 1) {
        return '参与者'
      } else if (val === 2) {
        return '研究者'
      } else if (val === 3) {
        return 'CRC'
      }
      return ''
    },
    filterSignStatus (val) {
      if (val === 0) {
        return '进行中'
      } else if (val === 1) {
        return '已完成'
      } else if (val === 2) {
        return '已拒绝'
      }
      return ''
    }
  },
  mounted () {
    this.modalHeight = Number(document.documentElement.clientHeight * 0.7)
    this.getConsentDetail()
    this.handleSee()
  },
  methods: {
    async getSignUrl () {
      if (this.detailDescription.status === 6) return
      if (!this.detailDescription.userFinshTime) {
        this.signUrl = await getSignUrl({ flowId: this.detailDescription.flowId, phone: this.clientICFApplyDTO.ptsPhone })
      } else if (this.detailDescription.witnessId && !this.detailDescription.witnessFinishTime) {
        this.signUrl = await getSignUrl({ flowId: this.detailDescription.flowId, phone: this.clientICFApplyDTO.witnessPhone })
      } else {
        this.signUrl = await getSignUrl({ flowId: this.detailDescription.flowId, phone: this.clientICFApplyDTO.researchPhone })
      }
    },
    getSignStatusColor (val) {
      if (val === 0) {
        return '#43D8D2'
      } else if (val === 1) {
        return 'green'
      } else if (val === 2) {
        return 'red'
      }
    },
    getConsentDetail () {
      this.videoList = []
      getConsentDetail({ id: this.$route.query.id })
        .then(res => {
          this.icfConfig = res.icfConfig
          this.consentProgress = res
          this.detailDescription = res.applyDto
          this.clientICFApplyDTO = res.clientICFApplyDTO
          this.detailLog = res.logDto
          this.detailVideoList = res.videoList
          this.propagandaList = res.propagandaList
          this.signList = res.signList

          this.recordVisible = false
          this.videoVisible = false
          this.signVisible = false
          this.appointVisible = false
          // 兼容老数据
          if (!this.signList || this.signList.length === 0) {
            if (this.detailDescription.userFinshTime || this.detailDescription.researcherFinshTime) {
              const params = {
                fileTitle: this.detailDescription.fileName
              }
              let i = 0

              if (this.detailDescription.researcherFinshTime) {
                i++
                params.researcherFinishTime = this.detailDescription.researcherFinshTime
                params.piNo = this.detailDescription.signPiName
              }
              if (this.detailDescription.signFileUrl) {
                params.fileUrl = this.detailDescription.signFileUrl
              }
              if (this.detailDescription.userFinshTime) {
                i++
                params.ptsFinishTime = this.detailDescription.userFinshTime
                params.ptsNo = this.detailDescription.ptsNo

                params.status = i === 2 ? 1 : 0
                this.signList.push(params)
              }
            }
          }
          if (this.signList && this.signList.length) {
            this.signVisible = true
          }
          switch (this.detailDescription.status) {
            case 2:
              this.recordVisible = true
              this.videoVisible = true

              break
            case 3:
              this.recordVisible = true
              this.videoVisible = true
              this.appointVisible = true
              break
            case 4:
            case 5:
              this.recordVisible = true
              this.videoVisible = true
              // this.signVisible = true
              break
            case 6:
              this.recordVisible = true
              this.videoVisible = true
              break
          }
          if (this.detailDescription.signType === 2) {
            this.videoVisible = false
          }

          if (this.detailDescription.videoUrl) {
            this.detailDescription.videoUrl.split(',').forEach(item => {
              this.videoList.push(this.$options.filters.dealImageUrl(item))
            })
          }
          // 有签署文件
          if (this.detailDescription.signFileUrl) {
            this.fileUrl = this.$options.filters.dealImageUrl(this.detailDescription.signFileUrl)
            // const loadingTask = pdf.createLoadingTask(this.fileUrl)
            // loadingTask.promise.then(pdf => {
            //   this.numPages = pdf.numPages
            //   this.initPage()
            // }).catch(err => {
            //   console.error('pdf 加载失败', err)
            //   this.initPage()
            // })
          }
          if (this.detailDescription.signStatus === 2) {
            this.getSignUrl()
          }
        })
        .catch(() => {})
    },
    initPage () {
      this.$nextTick(() => {
         window.scrollTo(0, 0)
      }, 200)
    },
    getVideoList (data) {
      const videoList = []
      if (data.videoUrl) {
        data.videoUrl.split(',').forEach(item => {
          videoList.push(this.$options.filters.dealImageUrl(item))
        })
      }
      return videoList
    },
    showPreview () {
      this.pdfVisible = true
      this.$nextTick(() => {
        this.newPdfUrl = 'demo.pdf'
        this.pdfh5 = new Pdfh5('#demo', {
          pdfurl: this.fileUrl,
          lazy: true,
          cMapUrl: 'https://unpkg.com/pdfjs-dist@2.0.943/cmaps/' // 插件默认的地址证书过期了
        })
      }, 200)
    },
    handleSee () {
      getApplyPaper({ applyId: this.$route.query.id }).then(res => {
        if (res.length > 0) {
          this.fileListSee = true
        }
      })
    },
    fileSee (url) {
      this.$router.push({
        name: 'file',
        query: {
          id: this.$route.query.id,
          file: url
        }
      })
    },
    uploadTap () {
      this.paVisible = true
    },
    // 文件上传
    async afterRead (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      const formData = new FormData()
      formData.append('file', file.file)
      const res = await upload(formData)
      file.status = 'success'
      file.fileUrl = res
    },
    // 纸质签署pdf文件预览
    handlePer (e) {
      if (e.file.type === 'application/pdf') {
        this.viewVisible = true
        this.getview(URL.createObjectURL(e.file))
      }
    },
    viewCancel () {
      this.viewVisible = false
    },
    // 纸质签署提交
    async handleSubmit () {
      console.log(this.fileList)
      if (this.fileList.length) {
        const params = {
          proId: this.detailDescription.proId,
          applyId: this.$route.query.id,
          fileUrls: this.fileList.map(item => item.fileUrl).join(',')
        }
        await applyPaper(params)
        this.paVisible = false
        this.getConsentDetail()
        this.handleSee()
      }
    },
    // 纸质签署取消提交关闭弹窗
    paCancel () {
      this.fileList = []
      this.paVisible = false
    },
    showRefused () {
      this.refusedVisible = true
      this.refusedRemark = ''
    },
    toRefused () {
      if (!this.refusedRemark) {
        this.$message.error('请输入拒绝理由')
        return
      }
      getApplyRefuse({
        applyId: this.$route.query.id,
        revokeReason: this.refusedRemark
      }).then(res => {
        this.$message.success('拒绝成功')
        this.refusedVisible = false
        this.getConsentDetail()
      })
    },
    getview (url) {
      this.$nextTick(() => {
        this.newPdfUrl = 'demo.pdf'
        this.pdfview = new Pdfh5('#pdfview', {
          pdfurl: url,
          lazy: true,
          cMapUrl: 'https://unpkg.com/pdfjs-dist@2.0.943/cmaps/' // 插件默认的地址证书过期了
        })
      }, 1200)
    }
  }
}
</script>
<style lang="less" scoped>

  .modalTitle{
    font-size: 22px;
    margin: 20px 0px 10px 0;
    font-weight: 500;
    color: #000;
    font-weight: 500;
  }
  .recordView{
    font-size: 16px;
    .row{
      margin-top: 5px;
    }
    .recordTitle{
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      font-weight: 500;
    }
    .modalRow{
      margin-top: 5px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      span {
        min-width: 110px;
        text-align:left;
        display:inline-block;
      }
    }
  }
  // .record-title{
  //   margin-top: 10px;
  // }
  .subTitle{
      color: #000;
      font-weight: 550;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      &-status{
        width: 60px;
        text-align: right;
        /deep/ .ant-tag {
          margin-right: 0px;
        }
      }
    }
    .desc{
      font-size: 12px;
      margin-bottom: 10px;
    }
    .sign-content {
      background: #F6F9FB;
      padding: 16px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      .sign-content-left {
        display: flex;
        align-items: center;
        .sign-status {
          margin-left: 10px;
        }
        .status-color {
          color: #FEBB98;
        }
      }
    }
    .sign-code {
      padding: 20px 0;
      text-align: center;
    }
    .file-upload {
      padding:20px;
      background: #FAFAFA;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .file-icon {
      font-size: 40px;
      color: #FA4E4E;
    }
    .menu{
      position: absolute;
      right: 0;
      cursor: pointer;
      // color: #E95A34;
      display: flex;
      .icon{
        width: 24px;
        height: 24px;
      }
      .text{
        color: #E95A34;
        margin-left: 10px;
        font-size: 16px;
      }
    }
</style>
